import i18n from '@/i18n'
import ReportHeading from '@/Utils/report-head-external'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, titleData) => {
    try {
      // Translation
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }

      // API Call
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)

      // PDF Content (reportHeadData)
      const pdfContent = [
          { columns: reportHeadData.reportHeadColumn },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]

      //
      pdfContent.push(
        { text: '', style: 'empty_space' }
      )

      //
      pdfContent.push(
        {
          text: [
            { text: `${vm.$t('research_manage.project_title')} : `, style: 'th' },
            { text: (vm.currentLocale === 'en' ? data.proposalInfo.project_title : data.proposalInfo.project_title_bn), style: 'td' }
          ],
          alignment: 'center'
        }
      )

      //
      pdfContent.push(
        {
          text: [
            { text: `${vm.$t('research_manage.thematic_area')} : `, style: 'th' },
            { text: vm.getThematicArea(data.proposalInfo.thematic_area_id), style: 'td' }
          ],
          alignment: 'center'
        }
      )

      //
      pdfContent.push(
        {
            table: {
              headerRows: 0,
              widths: ['16%', '16%', '16%', '16%', '16%', '16%'],
              body: [
                // 1
                [
                  {},
                  { text: `${vm.$t('research_manage.project_id')}:`, style: 'th', alignment: 'right' },
                  { text: data.proposalInfo.proposal_auto_id, style: 'td', alignment: 'left' },
                  { text: `${vm.$t('research_manage.total_budget')}:`, style: 'th', alignment: 'right' },
                  { text: data.proposalInfo.total_budget, style: 'td', alignment: 'left' },
                  {}
                ],
                // 2
                [
                  {},
                  { text: `${vm.$t('research_manage.project_start_date')}:`, style: 'th', alignment: 'right' },
                  { text: data.proposalInfo.from_date, style: 'td', alignment: 'left' },
                  { text: `${vm.$t('research_manage.project_end_date')}:`, style: 'th', alignment: 'right' },
                  { text: data.proposalInfo.to_date, style: 'td', alignment: 'left' },
                  {}
                ]
              ]
            },
            layout: 'noBorders',
            alignment: 'center'
        }
      )

      //
      pdfContent.push(
        { text: '', style: 'empty_space' }
      )

      // List Data
      const disbursedFunds = [
        [
          { text: vm.$t('research_manage.budget_head'), style: 'th', alignment: 'center' },
          { text: vm.$t('research_manage.allocation_amount'), style: 'th', alignment: 'center' },
          { text: vm.$t('research_manage.payment_date'), style: 'th', alignment: 'center' },
          { text: vm.$t('research_manage.disbursement_amount'), style: 'th', alignment: 'center' },
          { text: vm.$t('research_manage.due_amount'), style: 'th', alignment: 'center' }
        ]
      ]

      /**
       * Loop & push into disbursedFunds
       */
       for (var budgetHeadKey of Object.keys(data.disbursedFundList)) {
           for (let i = 0; i < data.disbursedFundList[budgetHeadKey].length; i++) {
               const disbursedFund = data.disbursedFundList[budgetHeadKey][i]
               if (i === 0) {
                    disbursedFunds.push([
                        { text: vm.getBudgetHeadName(budgetHeadKey), style: 'td', alignment: 'center', rowSpan: data.disbursedFundList[budgetHeadKey].length },
                        { text: vm.getBudgetHeadAllocation(budgetHeadKey), style: 'td', alignment: 'center', rowSpan: data.disbursedFundList[budgetHeadKey].length },
                        { text: disbursedFund.payment_date, style: 'td', alignment: 'center' },
                        { text: disbursedFund.requisition_amount, style: 'td', alignment: 'center' },
                        { text: disbursedFund.budget_wise_available_balance, style: 'td', alignment: 'center' }
                    ])
               } else {
                    disbursedFunds.push([
                        {},
                        {},
                        { text: disbursedFund.payment_date, style: 'td', alignment: 'center' },
                        { text: disbursedFund.requisition_amount, style: 'td', alignment: 'center' },
                        { text: disbursedFund.budget_wise_available_balance, style: 'td', alignment: 'center' }
                    ])
               }
           }
       }

       //
       pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['20%', '20%', '20%', '20%', '20%'],
          body: disbursedFunds
        }
      })

      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3],
              bold: true
            },
            td: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            header: {
              margin: [0, 1, 0, 15],
              fontSize: 16,
              alignment: 'center'
            },
            header2: {
              border: 0,
              fontSize: 14,
              margin: [0, 0, 0, 1]
            },
            address2: {
              border: 0,
              fontSize: 12,
              bold: true,
              margin: [0, 0, 0, 5]
            },
            empty_space: {
              margin: [10, 10, 10, 10]
            }
        }
      }

      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
  }

  export default {
    exportPdfDetails
  }

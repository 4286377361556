<template>
    <b-container fluid>
        <!-- Search -->
        <b-overlay :show="searchLoader">
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('research_manage.research_budget_ledger') }} {{ $t('globalTrans.search') }}</h4>
            </template>

            <template v-slot:body>
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset">
                    <b-row>
                        <b-col xl="6" lg="6" sm="12" xs="12">
                            <ValidationProvider name="Search" vid="search_by_title" rules="required|min_value:0|max_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="search_by_title"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.search')}}
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model.number="search.search_by_title"
                                        :options="searchByOptionsList"
                                        id="search_by_title"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col xl="6" lg="6" sm="12" xs="12" v-if="search.search_by_title === 1">
                            <ValidationProvider name="Thematic Area" vid="thematic_area_id" :rules="`${search.search_by_title === 1 ? 'required|min_value:1' : ''}`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="thematic_area_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('research_manage.thematic_area')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.thematic_area_id"
                                        :options="thematicAreaList"
                                        id="thematic_area_id"
                                        @change="getProjectTitleByThematicArea($event)"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col xl="6" lg="6" sm="12" xs="12" v-if="search.search_by_title === 1">
                            <ValidationProvider name="Project Tile" vid="project_title_id" :rules="`${search.search_by_title === 1 ? 'required|min_value:1' : ''}`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="project_title_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_title')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.project_title_id"
                                        :options="projectTitleList"
                                        id="project_title_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col xl="6" lg="6" sm="12" xs="12" v-if="search.search_by_title === 0">
                            <ValidationProvider name="Project ID" vid="project_id" :rules="`${search.search_by_title === 0 ? 'required' : ''}`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="project_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_id')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      class="form-control"
                                      v-model="search.project_id"
                                      id="project_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>

                    <b-row>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                        </div>
                    </b-row>
                </b-form>
                </ValidationObserver>
            </template>
        </iq-card>
        </b-overlay>
        <!-- /Search -->

        <!-- Report Data -->
        <b-row>
          <b-col md="12">
            <b-overlay :show="ledgerLoader">

                <b-row>
                    <b-col v-if="isLoaded" md="12">
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">{{ $t('research_manage.research_budget_ledger') }}  {{ $t('globalTrans.list') }}</h4>
                            </template>

                            <template v-slot:headerAction>
                                <b-button variant="primary" @click="pdfExport" class="mr-2">
                                    {{  $t('globalTrans.export_pdf') }}
                                </b-button>
                            </template>

                            <template v-slot:body>
                                <b-overlay>
                                    <b-row mt-5>
                                        <b-col md="12" class="table-responsive">
                                            <div style="border: 2px solid;margin:10px;padding:10px">
                                                <!-- List Report Head Component -->
                                                <b-row>
                                                    <b-col>
                                                    <external-list-report-head :base-url="agriResearchServiceBaseUrl" uri="/report-heading/detail" :org-id="4">
                                                        <template v-slot:projectNameSlot>
                                                        </template>
                                                        <template v-slot:default>
                                                            {{ $t('research_manage.research_budget_ledger') }}
                                                        </template>
                                                    </external-list-report-head>
                                                    </b-col>
                                                </b-row>

                                                <!--  -->
                                                <div class="row">
                                                    <div class="col-4" align="right" style="padding-right:5px">
                                                        <strong> {{ $t('research_manage.project_title') }} : </strong>
                                                    </div>
                                                    <div class="col-8" align="left" style="padding-left:5px">
                                                        {{ currentLocale === 'en' ? proposalInfo.project_title : proposalInfo.project_title_bn }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-4" align="right" style="padding-right:5px">
                                                        <strong> {{ $t('research_manage.thematic_area') }} : </strong>
                                                    </div>
                                                    <div class="col-8" align="left" style="padding-left:5px">
                                                        {{ getThematicArea(proposalInfo.thematic_area_id) }}
                                                    </div>
                                                </div>

                                                <div class="row" align="center">
                                                    <div class="col-md-6">
                                                        <strong> {{ $t('research_manage.project_id') }} : </strong>
                                                        {{ proposalInfo.proposal_auto_id }}
                                                    </div>
                                                    <div class="col-md-6">
                                                        <strong> {{ $t('research_manage.total_budget') }} : </strong>
                                                        {{ $n(proposalInfo.total_budget) }}
                                                    </div>
                                                </div>

                                                <div class="row" align="center">
                                                    <div class="col-md-6">
                                                        <strong> {{ $t('research_manage.project_start_date') }} : </strong>
                                                         {{ proposalInfo.from_date | dateFormat }}
                                                    </div>
                                                    <div class="col-md-6">
                                                        <strong> {{ $t('research_manage.project_end_date') }} : </strong>
                                                        {{ proposalInfo.to_date | dateFormat }}
                                                    </div>
                                                </div>

                                                <!-- Report Data -->
                                                <b-row>
                                                    <b-col>
                                                        <b-table-simple class="tg mt-3" bordered responsive>
                                                            <b-thead>
                                                                <b-tr>
                                                                    <b-th class="text-center">{{ $t('research_manage.budget_type') }}</b-th>
                                                                    <b-th class="text-center">{{ $t('research_manage.budget_head') }}</b-th>
                                                                    <b-th class="text-center">{{ $t('research_manage.allocation_amount') }}</b-th>
                                                                    <b-th class="text-center">{{ $t('research_manage.payment_date') }}</b-th>
                                                                    <b-th class="text-center">{{ $t('research_manage.disbursement_amount') }}</b-th>
                                                                    <b-th class="text-center">{{ $t('research_manage.due_amount') }}</b-th>
                                                                </b-tr>
                                                            </b-thead>
                                                            <b-tbody v-if="reportData.length > 0">
                                                                <template v-for="(item) in reportData">
                                                                    <b-tr v-for="(itm, index) in item" :key="index">
                                                                        <b-td v-if="index === 0" :rowspan="item.length">{{ getBudgetTypeName(itm.budget_type_id) }}</b-td>
                                                                        <b-td>{{ getBudgetName(itm.budget_head_id) }}</b-td>
                                                                        <b-td class="text-center">{{ $n(itm.totalAllocation) }}</b-td>
                                                                        <b-td class="text-center">
                                                                            <div v-for="(newitem, indx2) in itm.payments" :key="indx2">
                                                                                <span>{{ newitem.payment_date | dateFormat }}</span>
                                                                            </div>
                                                                        </b-td>
                                                                        <b-td class="text-center">
                                                                            <div v-for="(newitem, indx2) in itm.payments" :key="indx2">
                                                                                <span>{{ $n(newitem.totalDisburse) }}</span>
                                                                            </div>
                                                                        </b-td>
                                                                        <b-td class="text-center">
                                                                            <div v-for="(newitem, indx) in getBalance(itm.payments, itm.totalAllocation, index)" :key="indx">
                                                                                {{ newitem ? $n(newitem) : 0 }}
                                                                            </div>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </template>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-col>
                                                </b-row>

                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-overlay>
                            </template>
                        </iq-card>
                    </b-col>
                </b-row>

                <!-- Else -->
                <div v-if="nodata" class="panel-body text-center mt-3">
                    <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                </div>
            </b-overlay>
          </b-col>
        </b-row>
        <!-- /Report Data -->

    </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { projectListApi, budgetLedgerListApi } from '@/modules/agri-research/research-management/api/routes'
import ExportPdf from '@/modules/external-user-service/agri-research/pages/research-management/research-budget-ledger/export-pdf-details'
import ExternalListReportHead from '@/components/custom/ExternalListReportHead.vue'

export default {
    components: {
        ExternalListReportHead
    },
    created () {
    },
    data () {
        return {
            agriResearchServiceBaseUrl,
            ledgerLoader: false,
            nodata: false,
            searchLoader: false,
            projectTitleList: [],
            reportData: [],
            proposalInfo: {},

            search: {
                search_by_title: 1,
                thematic_area_id: 0,
                project_title_id: 0,
                project_id: ''
            },
            isLoaded: false
        }
    },
    computed: {
        thematicAreaList: function () {
            return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList
        },
        searchByOptionsList: function () {
            return [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Project Title' : 'প্রজেক্ট টাইটেল' },
                { value: 0, text: this.$i18n.locale === 'en' ? 'Project ID' : 'প্রজেক্ট আইডি' }
            ]
        },
        currentLocale: function () {
            return this.$i18n.locale
        }
    },
    watch: {
        'search.search_by_title': function (newVal, oldVal) {
            this.search.thematic_area_id = 0
            this.search.project_title_id = 0
            this.search.project_id = ''
            this.$refs.form.reset()
        }
    },
    methods: {
        getBalance (amount, total, index) {
            let balance = 0
            let newList = []
            if (index === 0) {
               balance = 0
               newList = []
            }
            amount.forEach((data, indx) => {
                balance = balance + parseFloat(data.totalDisburse)
                const newBlnc = parseFloat(total) - balance
                newList.push(newBlnc)
            })
            return newList
        },
        getBudgetTypeName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetTypeList.find(item => item.value === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        },
        getBudgetName (Id) {
            const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(item => item.value === parseInt(Id))
            if (this.$i18n.locale === 'en') {
                 return Obj.text_en
             } else {
                 return Obj.text_bn
             }
        },
        getBudgetHeadName (budgetHeadId) {
            const tmpData = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.budgetHeadList.find(item => item.value === parseInt(budgetHeadId))
            if (this.$i18n.locale === 'bn') {
                return tmpData.text_bn
            } else {
                return tmpData.text_en
            }
        },
        getThematicArea (thematicAreaId) {
            const tmpData = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(item => item.value === parseInt(thematicAreaId))
            if (this.$i18n.locale === 'bn') {
                return tmpData.text_bn
            } else {
                return tmpData.text_en
            }
        },
        /**
         * @ change method
         */
        async getProjectTitleByThematicArea (thematicAreaId) {
            this.search.project_title_id = 0
            if (thematicAreaId > 0) {
                this.projectTitleList = await this.getProjectList(thematicAreaId)
            } else {
                this.projectTitleList = []
            }
        },
        async getProjectList (thematicAreaId) {
            this.searchLoader = true
            const params = { thematic_area_id: thematicAreaId }
            const result = await RestApi.getData(agriResearchServiceBaseUrl, projectListApi, params)
            this.searchLoader = false

            if (!result.success) return []

            return result.data.map((obj, key) => {
                return { value: obj.value, text: this.currentLocale === 'en' ? obj.text_en : obj.text_bn }
            })
        },
        async loadData () {
            this.ledgerLoader = true
            const params = Object.assign({}, this.search)
            const result = await RestApi.getData(agriResearchServiceBaseUrl, budgetLedgerListApi, params)

            if (result.success && result.data) {
                this.reportData = result.data
                this.proposalInfo = result.proposalInfo
                this.nodata = false
                this.isLoaded = true
            } else {
                this.reportData = []
                this.nodata = true
            }
            this.ledgerLoader = false
        },
        async pdfExport () {
            this.ledgerLoader = true
            const reportTitle = this.$t('research_manage.research_budget_ledger')
            const data = JSON.parse(JSON.stringify(this.reportData))
            await ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 4, reportTitle, data, this)
            this.ledgerLoader = false
        }
    }
}
</script>
